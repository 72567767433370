import { useState } from 'react';

export const Modal = (initialValue=false) => {
    
    const [value, setValue] = useState(initialValue)
    const toggleValue = () => setValue(!value)
    
    if(value === false) {
        document.querySelector("html").style.overflow = 'auto';
    } else {
        document.querySelector("html").style.overflow = 'hidden';
    }
    
    return [value, toggleValue]
};