import IndexPage from './pages'
import React, { useEffect, useState } from 'react'
import './scss/app.scss'
import Bottom from './pages/bottom'
import { BigText, Popupfinish } from './components'
import { Modal } from './hooks/Modal/Modal'
import Footer from './layouts/footer/footer'
//import "swiper/css/bundle";

function App() {
    
    const [popup, toggleValue] = Modal(false)
    const [mount, setMount] = useState(true)
    
    useEffect(() => {
        if(mount) {
            function showPopup() {
                toggleValue(true)
            }
    
            setTimeout(showPopup, 5000)
        }
        setMount(false)
       
    }, [toggleValue, mount]);
    
    return (
        <>
            { popup && <Popupfinish toggleValue={ toggleValue }/> }
            <div className="AppIndex">
                <IndexPage toggleValue={ toggleValue }/>
            </div>
            <div className={ "bottomIndex" }>
                <Bottom toggleValue={ toggleValue }/>
                <BigText/>
                <Footer />
            </div>
        </>
    )
}

export default App
