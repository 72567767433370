import React from 'react'

const Footer = () => {
    return (
        <>
            <div className={ "footer" }>
                Сделано на базе <span> ninewebuilder</span>
            </div>
        </>
    )
}

export default Footer