import React from 'react'
import 'normalize.css'
import Header from '../layouts/header/heder'
import * as ReactGA from 'react-ga'
ReactGA.initialize('DC-11559578');

const IndexPage = ({toggleValue}) => {
    
    const clickGa = () => {
        toggleValue(false)
        ReactGA.event({
            category: 'nina',
            action: 'click',
            label: 'download'
        });
    }
    
    return (
        <>
            <div className={"container"}>
                <Header/>
                <div className="textIndex">
                    <h1>МУЖ УШЁЛ <br/> К&#160;ЛЮБОВНИЦЕ</h1>
                    <p>Оставил меня одну с тремя детьми…
                        и&#160;двумя бывшими мужьями.&#160;Я&#160;создала
                        этот сайт, чтобы мы, жены, смогли хоть
                        как-то отомстить</p>
                    <span>Нина</span>
                    <div onClick={() => clickGa()} className="loading loadingPl">
                        ОТКРЫТЬ БАЗУ ЛЮБОВНИЦ
                    </div>
                </div>
                <div className="mobileDiv">
                    <div onClick={() => clickGa()} className="loading">ОТКРЫТЬ БАЗУ ЛЮБОВНИЦ</div>
                </div>
                <div className={"bottomArrow"}></div>
            </div>
        </>
    )
}

export default IndexPage