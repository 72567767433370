import React from 'react'

const Header = () => {
    return (
        <>
            <div className={"header"}>
                <div className={"logo"}>
                    <a href={"/"}>Любовницы.NET</a>
                </div>
                <div className={"line"} />
                <div className={"header_text"}>
                    ТЕ САМЫЕ «ПРОСТО ЗНАКОМЫЕ»
                </div>
            </div>
        </>
    )
}

export default Header