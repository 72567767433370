import React from 'react'
import style from './popupfinish.module.scss'
import banner from '../../assets/img/banner.png'
import close from '../../assets/img/close.png'

const PopupFinish = ({toggleValue}) => {
    return (
        <div className={style.popupShow}>
            <div className={style.fon} onClick={() => toggleValue(false)}/>
            <div className={style.closeMobile}>
                <img src={close} alt="close" onClick={() => toggleValue(false)}/>
            </div>
            <div className={style.popup}>
                <div className={style.close}>
                    <img src={close} alt="close" onClick={() => toggleValue(false)}/>
                </div>
                <div className={style.img}>
                    <img src={banner} alt="banner"/>
                </div>
                <div className={style.text}>
                    <h1>КАК УВЕСТИ МУЖА У&#160;ЛЮБОВНИЦЫ?</h1>
                    <div>СМОТРИ <strong>СЕГОДНЯ&#160;В&#160;20:00</strong> ПРЕМЬЕРУ&#160;СЕРИАЛА НИНА НА&#160;ТНТ</div>
                </div>
            </div>
        </div>
    )
}

export default PopupFinish